import React, { Component } from "react";
import Grid from "@mui/material/Grid";
class Section4 extends Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          // height: "800px",
          position: "relative",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundImage: `url(
            "https://res.cloudinary.com/eajarstore/image/upload/v1657561124/shiva/WhatsApp_Image_2022-07-11_at_11.11.07_AM_s7m5oa.jpg"
          )`,
        }}
        id="section4"
      >
        {/* <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "top",
          }}
          alt="sas"
          src="https://res.cloudinary.com/eajarstore/image/upload/v1657561124/shiva/WhatsApp_Image_2022-07-11_at_11.11.07_AM_s7m5oa.jpg"
        /> */}
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            backgroundColor: "rgb(0 0 0 / 72%)",
            textAlign: "center",
          }}
        >
          <h1
            style={{
              fontSize: "60px",
              fontFamily: "Oswald,Open Sans,Helvetica,sans-serif",
              color: "#fff",
              marginTop: "100px",
            }}
          >
            SKYLINE VFX PIPELINE
          </h1>
          <p style={{ fontWeight: "bold", color: "#fff" }}>
            We follow unique work process in handling our projects for deliver
            better output within in stipulated time
          </p>
          <Grid container>
            <Grid Item xs={0} sm={1} md={1}></Grid>
            <Grid
              Item
              xs={12}
              sm={2}
              md={2}
              style={{
                border: "1px dashed grey",
                borderRadius: "10px",
                padding: "10px 30px",
                width: "100px",
                margin: "5px",
                backgroundColor: "#e9e9e938",
                textAlign: "center",
                overFlow: "hidden",
              }}
            >
              <p
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                BIDDING
              </p>
            </Grid>
            <Grid
              Item
              xs={12}
              sm={2}
              md={2}
              style={{
                border: "1px dashed grey",
                borderRadius: "10px",
                padding: "10px 30px",
                width: "100px",
                margin: "5px",
                backgroundColor: "#e9e9e938",
                textAlign: "center",
                overFlow: "hidden",
              }}
            >
              <p
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                APPROVAL
              </p>
            </Grid>
            <Grid
              Item
              xs={12}
              sm={2}
              md={2}
              style={{
                border: "1px dashed grey",
                borderRadius: "10px",
                padding: "10px 30px",
                width: "100px",
                margin: "5px",
                backgroundColor: "#e9e9e938",
                textAlign: "center",
                overFlow: "hidden",
              }}
            >
              <p
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                WORK
              </p>
            </Grid>
            <Grid
              Item
              xs={12}
              sm={2}
              md={2}
              style={{
                border: "1px dashed grey",
                borderRadius: "10px",
                padding: "10px 30px",
                width: "100px",
                margin: "5px",
                backgroundColor: "#e9e9e938",
                textAlign: "center",
                overFlow: "hidden",
              }}
            >
              <p
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                OUTPUT
              </p>
            </Grid>
            <Grid
              Item
              xs={12}
              sm={2}
              md={2}
              style={{
                border: "1px dashed grey",
                borderRadius: "10px",
                padding: "10px 30px",
                width: "100px",
                margin: "5px",
                backgroundColor: "#e9e9e938",
                textAlign: "center",
                overFlow: "hidden",
              }}
            >
              <p
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                }}
              >
                APPROVED
              </p>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
export default Section4;
