import React, { Component } from "react";

class Section11 extends Component {
  render() {
    return (
      <div>
        <div
          style={{
            width: "100%",
            height: "300px",
            position: "relative",
            backgroundAttachment: "fixed",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundImage: `url(
              "https://res.cloudinary.com/eajarstore/image/upload/v1657561122/shiva/WhatsApp_Image_2022-07-11_at_11.11.01_AM_djmggv.jpg"
            )`,
          }}
          id="section11"
        >
          {/* <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "top",
              backgroundAttachment: "fixed",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
            alt="sas"
            src="https://res.cloudinary.com/eajarstore/image/upload/v1657561123/shiva/WhatsApp_Image_2022-07-11_at_11.11.03_AM_hzvyuk.jpg"
          /> */}
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              backgroundColor: "rgb(0 0 0 / 72%)",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                backgroundColor: "#ffffff26",
                width: "80%",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  color: "white",
                  textTransform: "uppercase",
                  fontWeight: "bold",
                }}
              >
                Hyderabad, India <p>info@skylinevfxstudio.com</p>
                <p>© COPYRIGHT 2022 SKYLINEVFX STUDIOS PVT LTD.</p>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Section11;
