import React, { Component } from "react";

class Section7 extends Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          //   height: "350px",
          textAlign: "center",
          marginBottom: "50px",
        }}
        id="section7"
      >
        <h1
          style={{
            fontSize: "60px",
            fontFamily: "Oswald,Open Sans,Helvetica,sans-serif",
            color: "#3e3e3e",
            marginTop: "50px",
            // marginBottom: "0px",
          }}
        >
          Our Work
        </h1>
        {/* <img
          src="https://justvfxstudios.com/wp-content/uploads/2021/06/JUSTVFX-STUDIOS-PVT-LTD-CoAC-Exp.-07.22.22_page-0001-1024x791.jpg"
          alt="tpn"
          style={{ width: "70%", border: "5px solid #50485b" }}
        /> */}
        <div
          style={{
            padding: "50% 0 0 0",
            position: "relative",
          }}
        >
          <iframe
            src="https://bafybeielx75id6m44f5cd2i7muwsjwwext6xg756owrm4riarfi7yl2r2e.ipfs.nftstorage.link/"
            frameborder="0"
            allow="autoplay"
            muted="true"
            // allowfullscreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            title="SkylineVFX"
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
    );
  }
}
export default Section7;
