import React, { Component } from "react";
import ResponsiveAppBar from "./Header";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section4 from "./Section4";
import Section5 from "./Section5";
import Section6 from "./Section6";
import Section7 from "./Section7";
import Section9 from "./Section9";
import Section10 from "./Section10";
import Section11 from "./Section11";
class Home extends Component {
  render() {
    return (
      <div>
        <ResponsiveAppBar />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        {/* <Section9 /> */}
        <Section10 />
        <Section11 />
      </div>
    );
  }
}
export default Home;
