import React, { Component } from "react";
import Button from "@mui/material/Button";
class Section2 extends Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "550px",
          backgroundColor: "#000",
          position: "relative",
          overflow: "hidden",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundImage: `url(
            "https://res.cloudinary.com/eajarstore/image/upload/v1657561126/shiva/WhatsApp_Image_2022-07-11_at_11.10.57_AM_y6et1k.jpg"
          )`,
        }}
        id="section2"
      >
        {/* <iframe
          style={{
            width: "100%",
            height: "100%",
          }}
          allow="autoplay; fullscreen; picture-in-picture; xr-spatial-tracking; clipboard-write"
          frameborder="0"
          src="https://res.cloudinary.com/eajarstore/video/upload/v1657566039/shiva/ffff_oknrjk.mp4"
        ></iframe> */}

        {/* <img
          title="uniq"
          alt="As"
          style={{
            width: "100%",
            height: "100%",
            border: "0px",
            objectFit: "cover",
            // objectPosition: "top",
          }}
          src="https://res.cloudinary.com/eajarstore/image/upload/v1657561128/shiva/WhatsApp_Image_2022-07-11_at_11.11.11_AM_shrzue.jpg"
        /> */}
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            textAlign: "center",
            backgroundColor: "#000000c9",
          }}
        >
          <h2
            style={{
              fontFamily: "Raleway",
              fontSize: "40px",
              fontWeight: "700",
              color: "#fff",
              marginTop: "18%",
            }}
          >
            WELCOME TO SKYLINE VFX STUDIOS
          </h2>
          <a href="#section3" style={{ textDecoration: "none" }}>
            <Button
              variant="outlined"
              style={{
                fontWeight: "bold",
                color: "#fff",
                border: "1px solid white",
              }}
            >
              Read More
            </Button>
          </a>
        </div>
      </div>
    );
  }
}
export default Section2;
