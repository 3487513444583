import React, { Component } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
class Section10 extends Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          //   height: "350px",
          textAlign: "center",
          marginBottom: "50px",
        }}
        id="section10"
      >
        <h1
          style={{
            fontSize: "50px",
            fontFamily: "Oswald,Open Sans,Helvetica,sans-serif",
            color: "#3e3e3e",
            marginTop: "50px",
          }}
        >
          WHY DO YOU CHOOSE Skyline VFX STUDIO?
        </h1>
        <p style={{ fontWeight: "500", color: "#3e3e3e" }}>
          At Skyline VFX we provide wide range of post-production services
          including rotoscoping, paint, matchmove, composting.
        </p>
        <Grid container>
          <Grid Item xs={2} sm={2} md={2}></Grid>
          <Grid Item xs={8}>
            <Accordion
              style={{ border: "1px solid #8080807a" }}
              elevation={0}
              square
              expanded
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ borderBottom: "1px solid #8080807a" }}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  HIGH SPEED BROADBAND WIDTH
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  We are ideal VFX maker having high speed broadband facility
                  which makes it possible for us to upload content.
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              style={{ border: "1px solid #8080807a" }}
              elevation={0}
              square
              expanded
            >
              <AccordionSummary
                aria-controls="panel2a-content"
                id="panel2a-header"
                style={{ borderBottom: "1px solid #8080807a" }}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  EXPERIENCE
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  From years Skyline VFX has been a leading provider of
                  post-production services in entertainment industry including
                  commercials, TV shows,and Feature films etc. both in Domestic
                  and international
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{ border: "1px solid #8080807a" }}
              elevation={0}
              square
              expanded
            >
              <AccordionSummary
                aria-controls="panel3a-content"
                id="panel3a-header"
                style={{ borderBottom: "1px solid #8080807a" }}
              >
                <Typography style={{ fontWeight: "bold" }}>FEATURES</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Features those makes us different from other includes: 1.
                  Quality work 2. Fast turnaround 3. TPN Standards 4. High
                  Secured Facility
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{ border: "1px solid #8080807a" }}
              elevation={0}
              square
              expanded
            >
              <AccordionSummary
                aria-controls="panel4a-content"
                id="panel4a-header"
                style={{ borderBottom: "1px solid #8080807a" }}
              >
                <Typography style={{ fontWeight: "bold" }}>TEAM</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  we are a talent driven studio that was formed with an ambition
                  for creating excellent solutions. We always lookout to provide
                  better solutions for our clients with superb skills of our
                  team.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default Section10;
