import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import React, { Component } from "react";
import BaseRouter from "./Components/Routes";
class App extends Component {
  render() {
    return (
      <>
        <Router>
          <BaseRouter />
        </Router>
      </>
    );
  }
}

export default App;
