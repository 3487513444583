import React, { Component } from "react";
import Grid from "@mui/material/Grid";

class Section5 extends Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          //   height: "350px",
          textAlign: "center",
          marginBottom: "50px",
        }}
        id="section5"
      >
        <h1
          style={{
            fontSize: "60px",
            fontFamily: "Oswald,Open Sans,Helvetica,sans-serif",
            color: "#3e3e3e",
            marginTop: "50px",
          }}
        >
          Our Team Strength
        </h1>

        <Grid container>
          <Grid item xs={0} sm={1} md={1}></Grid>
          <Grid item xs={12} sm={2} md={2} style={{ margin: "5px" }}>
            <div
              style={{
                padding: "20px",
                textAlign: "center",
                border: "1px dashed grey",
              }}
            >
              <h1
                style={{
                  margin: "0px",
                  color: "#8dc63f",
                  fontSize: "60px",
                  fontFamily: '"Oswald",Open Sans,Helvetica,sans-serif',
                  fontWeight: "400",
                }}
              >
                50
              </h1>
              <h4
                style={{
                  margin: "0px",
                  color: "black",
                  fontSize: "20px",
                  lineHeight: "1.4em",
                  fontWeight: "400",
                }}
              >
                ROTO & KEYING
              </h4>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3} style={{ margin: "5px" }}>
            {" "}
            <div
              style={{
                padding: "20px",
                textAlign: "center",
                border: "1px dashed grey",
              }}
            >
              <h1
                style={{
                  margin: "0px",
                  color: "#8dc63f",
                  fontSize: "60px",
                  fontFamily: '"Oswald",Open Sans,Helvetica,sans-serif',
                  fontWeight: "400",
                }}
              >
                30
              </h1>
              <h4
                style={{
                  margin: "0px",
                  color: "black",
                  fontSize: "20px",
                  lineHeight: "1.4em",
                  fontWeight: "400",
                }}
              >
                PAINT
              </h4>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3} style={{ margin: "5px" }}>
            {" "}
            <div
              style={{
                padding: "20px",
                textAlign: "center",
                border: "1px dashed grey",
              }}
            >
              <h1
                style={{
                  margin: "0px",
                  color: "#8dc63f",
                  fontSize: "60px",
                  fontFamily: '"Oswald",Open Sans,Helvetica,sans-serif',
                  fontWeight: "400",
                }}
              >
                20
              </h1>
              <h4
                style={{
                  margin: "0px",
                  color: "black",
                  fontSize: "20px",
                  lineHeight: "1.4em",
                  fontWeight: "400",
                }}
              >
                MATCHMOVE
              </h4>
            </div>
          </Grid>
          <Grid item xs={12} sm={2} md={2} style={{ margin: "5px" }}>
            {" "}
            <div
              style={{
                padding: "20px",
                textAlign: "center",
                border: "1px dashed grey",
              }}
            >
              <h1
                style={{
                  margin: "0px",
                  color: "#8dc63f",
                  fontSize: "60px",
                  fontFamily: '"Oswald",Open Sans,Helvetica,sans-serif',
                  fontWeight: "400",
                }}
              >
                15
              </h1>
              <h4
                style={{
                  margin: "0px",
                  color: "black",
                  fontSize: "20px",
                  lineHeight: "1.4em",
                  fontWeight: "400",
                }}
              >
                COMPOSITING
              </h4>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default Section5;
