import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import LocalMoviesIcon from "@mui/icons-material/LocalMovies";
import BrushIcon from "@mui/icons-material/Brush";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import TextureRoundedIcon from "@mui/icons-material/TextureRounded";
class Section6 extends Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          // height: "1000px",
          position: "relative",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundImage: `url(
            "https://res.cloudinary.com/eajarstore/image/upload/v1657561124/shiva/WhatsApp_Image_2022-07-11_at_11.11.12_AM_hyhqfm.jpg"
          )`,
        }}
        id="section6"
      >
        {/* <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            objectPosition: "top",
          }}
          alt="sas"
          src="https://res.cloudinary.com/eajarstore/image/upload/v1657561124/shiva/WhatsApp_Image_2022-07-11_at_11.11.12_AM_hyhqfm.jpg"
        /> */}
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            backgroundColor: "rgb(0 0 0 / 72%)",
            textAlign: "center",
          }}
        >
          <h1
            style={{
              fontSize: "40px",
              fontFamily: "Oswald,Open Sans,Helvetica,sans-serif",
              color: "#fff",
              marginTop: "100px",
            }}
          >
            OUR SERVICES
          </h1>
          <p style={{ fontWeight: "500", color: "#fff" }}>
            At SKYLINE VFX we provide wide range of post-production services
            including rotoscoping, paint, matchmove, composting.
          </p>
          <Grid container>
            <Grid Item xs={0} sm={2} md={2}></Grid>
            <Grid Item xs={5} sm={2} md={2}>
              <Grid container>
                <Grid Item xs={1}></Grid>
                <Grid Item xs={10} sm={10} md={10}>
                  <div
                    style={{
                      width: "100%",
                      height: "180px",
                      borderRadius: "50%",
                      border: "1px solid #646464bf",
                      color: "white",
                      alignContent: "center",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "50px",
                    }}
                  >
                    <LocalMoviesIcon style={{ fontSize: "35px" }} />
                  </div>
                </Grid>
                <Grid
                  Item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      fontSize: "18px",
                      //   fontWeight: "900",
                      textTransform: "uppercase",
                    }}
                  >
                    RotoScoping
                  </p>
                </Grid>
                <Grid Item xs={12}>
                  <ul style={{ textAlign: "initial", fontSize: "14px" }}>
                    <li style={{ color: "white" }}>VFX Roto</li>
                    <li style={{ color: "white" }}>
                      Hard & Motion Blur matters
                    </li>
                    <li style={{ color: "white" }}>Stereo Roto</li>
                    <li style={{ color: "white" }}>
                      {" "}
                      Nuke / Silhouette Splines
                    </li>
                    <li style={{ color: "white" }}>Green Screen Extraction</li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
            <Grid Item xs={5} sm={2} md={2}>
              <Grid container>
                <Grid Item xs={1}></Grid>
                <Grid Item xs={10}>
                  <div
                    style={{
                      width: "100%",
                      height: "180px",
                      borderRadius: "50%",
                      border: "1px solid #646464bf",
                      color: "white",
                      alignContent: "center",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "50px",
                    }}
                  >
                    <BrushIcon style={{ fontSize: "35px" }} />
                  </div>
                </Grid>
                <Grid
                  Item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      fontSize: "18px",
                      //   fontWeight: "900",
                      textTransform: "uppercase",
                    }}
                  >
                    Paint
                  </p>
                </Grid>
                <Grid Item xs={12}>
                  <ul style={{ textAlign: "initial", fontSize: "14px" }}>
                    <li style={{ color: "white" }}>
                      Rig/Wire/Object/Marker Removals{" "}
                    </li>
                    <li style={{ color: "white" }}>Clean Plate Generation</li>
                    <li style={{ color: "white" }}>Stereo Roto</li>
                    <li style={{ color: "white" }}> Beauty cleanups</li>
                    <li style={{ color: "white" }}>Reflections cleanups</li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
            <Grid Item xs={5} sm={2} md={2}>
              <Grid container>
                <Grid Item xs={1}></Grid>
                <Grid Item xs={10}>
                  <div
                    style={{
                      width: "100%",
                      height: "180px",
                      borderRadius: "50%",
                      border: "1px solid #646464bf",
                      color: "white",
                      alignContent: "center",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "50px",
                    }}
                  >
                    <VideocamRoundedIcon style={{ fontSize: "35px" }} />
                  </div>
                </Grid>
                <Grid
                  Item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      fontSize: "18px",
                      //   fontWeight: "900",
                      textTransform: "uppercase",
                    }}
                  >
                    Matchmove
                  </p>
                </Grid>
                <Grid Item xs={12}>
                  <ul style={{ textAlign: "initial", fontSize: "14px" }}>
                    <li style={{ color: "white" }}>Object Tracking</li>
                    <li style={{ color: "white" }}>Camera Tracking</li>
                    <li style={{ color: "white" }}> Body Tracking</li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
            <Grid Item xs={5} sm={2} md={2}>
              <Grid container>
                <Grid Item xs={1}></Grid>
                <Grid Item xs={10}>
                  <div
                    style={{
                      width: "100%",
                      height: "180px",
                      borderRadius: "50%",
                      border: "1px solid #646464bf",
                      color: "white",
                      alignContent: "center",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "50px",
                    }}
                  >
                    <TextureRoundedIcon style={{ fontSize: "35px" }} />
                  </div>
                </Grid>
                <Grid
                  Item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      color: "white",
                      fontSize: "18px",
                      //   fontWeight: "900",
                      textTransform: "uppercase",
                    }}
                  >
                    Compositing
                  </p>
                </Grid>
                <Grid Item xs={12}>
                  <ul style={{ textAlign: "initial", fontSize: "14px" }}>
                    <li style={{ color: "white" }}>
                      Bringing different elements together into one single
                      component
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
export default Section6;
