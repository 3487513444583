import { Routes, Route } from "react-router-dom";
import React, { Fragment } from "react";
// import Login from "./Login";
// import Register from "./Signup";
// import Welcome from "./Welcome";
import Home from "./Home";
function BaseRouter() {
  return (
    <Fragment>
      <Routes>
        <Route exact path="/" element={<Home />} />
      </Routes>
    </Fragment>
  );
}

export default BaseRouter;
