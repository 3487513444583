import React, { Component } from "react";

class Section9 extends Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          //   height: "350px",
          textAlign: "center",
          marginBottom: "50px",
        }}
        id="section9"
      >
        <h1
          style={{
            fontSize: "50px",
            fontFamily: "Oswald,Open Sans,Helvetica,sans-serif",
            color: "#3e3e3e",
            marginTop: "50px",
          }}
        >
          WHAT CLIENTS SAY
        </h1>
      </div>
    );
  }
}
export default Section9;
