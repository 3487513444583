import React, { Component } from "react";

class Section3 extends Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          //   height: "350px",
          textAlign: "center",
        }}
        id="section3"
      >
        <h1
          style={{
            fontSize: "60px",
            fontFamily: "Oswald,Open Sans,Helvetica,sans-serif",
            color: "#3e3e3e",
            marginTop: "100px",
          }}
        >
          ABOUT SKYLINE VFX
        </h1>

        <p style={{ margin: "5% 5%", fontWeight: "500", color: "#585757" }}>
          SKYLINE VFX is the unique visual effects studio delivering work with
          high quality and fast turnaround and running successfully satisfying
          all clients. We specialized in doing any kind of roto (experts in
          motion blur, fur, hair), prep & paint(experts in beautification, wire
          cleanups, reflection cleanups), and Matchmove – Rotoanim. <br />
          <br />
          We are vendors for many world-class VFX studios and we have been
          appreciated by clients for our works. We cater services for TV series,
          Commercials, and Feature Films. Artists at SKYLINE VFX are highly
          skilled, creative, and passionate.
        </p>
      </div>
    );
  }
}
export default Section3;
