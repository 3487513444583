import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="static"
      style={{ backgroundColor: "white", color: "black" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img
              src="https://res.cloudinary.com/eajarstore/image/upload/v1657018840/shiva/logo_cdq6gw.jpg"
              alt="logo"
              style={{ width: "120px", height: "50px" }}
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem style={{ color: "black" }} onClick={handleCloseNavMenu}>
                <a href={"#section2"} style={{ textDecoration: "none" }}>
                  <Typography
                    variant="h6"
                    style={{
                      padding: "5px",
                      // border: "1px solid grey",
                      margin: "5px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                    onClick={handleCloseNavMenu}
                    textAlign="center"
                    sx={{ color: "black", display: "block" }}
                  >
                    Home
                  </Typography>
                </a>
              </MenuItem>
              <MenuItem style={{ color: "black" }} onClick={handleCloseNavMenu}>
                <a href={"#section3"} style={{ textDecoration: "none" }}>
                  <Typography
                    variant="h6"
                    style={{
                      padding: "5px",
                      // border: "1px solid grey",
                      margin: "5px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                    onClick={handleCloseNavMenu}
                    textAlign="center"
                    sx={{ color: "black", display: "block" }}
                  >
                    About US
                  </Typography>
                </a>
              </MenuItem>
              <MenuItem style={{ color: "black" }} onClick={handleCloseNavMenu}>
                <a href={"#section4"} style={{ textDecoration: "none" }}>
                  <Typography
                    variant="h6"
                    style={{
                      padding: "5px",
                      // border: "1px solid grey",
                      margin: "5px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                    onClick={handleCloseNavMenu}
                    textAlign="center"
                    sx={{ color: "black", display: "block" }}
                  >
                    Services
                  </Typography>
                </a>
              </MenuItem>
              <MenuItem style={{ color: "black" }} onClick={handleCloseNavMenu}>
                <a href={"#section7"} style={{ textDecoration: "none" }}>
                  <Typography
                    variant="h6"
                    style={{
                      padding: "5px",
                      // border: "1px solid grey",
                      margin: "5px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                    onClick={handleCloseNavMenu}
                    textAlign="center"
                    sx={{ color: "black", display: "block" }}
                  >
                    Our Work
                  </Typography>
                </a>
              </MenuItem>
              <MenuItem style={{ color: "black" }} onClick={handleCloseNavMenu}>
                <a href={"#section10"} style={{ textDecoration: "none" }}>
                  <Typography
                    variant="h6"
                    style={{
                      padding: "5px",
                      // border: "1px solid grey",
                      margin: "5px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                    onClick={handleCloseNavMenu}
                    textAlign="center"
                    sx={{ color: "black", display: "block" }}
                  >
                    Features
                  </Typography>
                </a>
              </MenuItem>
              <MenuItem style={{ color: "black" }} onClick={handleCloseNavMenu}>
                <a href={"#section11"} style={{ textDecoration: "none" }}>
                  <Typography
                    variant="h6"
                    style={{
                      padding: "5px",
                      // border: "1px solid grey",
                      margin: "5px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                    onClick={handleCloseNavMenu}
                    textAlign="center"
                    sx={{ color: "black", display: "block" }}
                  >
                    contact
                  </Typography>
                </a>
              </MenuItem>
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img
              src="https://res.cloudinary.com/eajarstore/image/upload/v1657018840/shiva/logo_cdq6gw.jpg"
              alt="logo"
              style={{ width: "120px", height: "50px" }}
            />
          </Typography>
          <Box
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
            style={{ marginLeft: "20px" }}
          >
            <a href={"#section2"} style={{ textDecoration: "none" }}>
              <Typography
                variant="h6"
                style={{
                  padding: "10px",
                  // border: "1px solid grey",
                  margin: "5px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
                onClick={handleCloseNavMenu}
                sx={{ color: "black", display: "block" }}
              >
                Home
              </Typography>
            </a>
            <a href={"#section3"} style={{ textDecoration: "none" }}>
              <Typography
                variant="h6"
                style={{
                  padding: "10px",
                  // border: "1px solid grey",
                  margin: "5px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
                onClick={handleCloseNavMenu}
                sx={{ color: "black", display: "block" }}
              >
                ABOUT US
              </Typography>
            </a>
            <a href={"#section4"} style={{ textDecoration: "none" }}>
              <Typography
                variant="h6"
                style={{
                  padding: "10px",
                  // border: "1px solid grey",
                  margin: "5px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
                onClick={handleCloseNavMenu}
                sx={{ color: "black", display: "block" }}
              >
                Services
              </Typography>
            </a>
            <a href={"#section7"} style={{ textDecoration: "none" }}>
              <Typography
                variant="h6"
                style={{
                  padding: "10px",
                  // border: "1px solid grey",
                  margin: "5px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
                onClick={handleCloseNavMenu}
                sx={{ color: "black", display: "block" }}
              >
                Our Work
              </Typography>
            </a>
            <a href={"#section10"} style={{ textDecoration: "none" }}>
              <Typography
                variant="h6"
                style={{
                  padding: "10px",
                  // border: "1px solid grey",
                  margin: "5px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
                onClick={handleCloseNavMenu}
                sx={{ color: "black", display: "block" }}
              >
                FEATURES
              </Typography>
            </a>
            <a href={"#section11"} style={{ textDecoration: "none" }}>
              <Typography
                variant="h6"
                style={{
                  padding: "10px",
                  // border: "1px solid grey",
                  margin: "5px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
                onClick={handleCloseNavMenu}
                sx={{ color: "black", display: "block" }}
              >
                CONTACT
              </Typography>
            </a>
            {/* {pages.map((page) => (
              <Typography
                variant="h6"
                key={page}
                style={{
                  padding: "10px",
                  // border: "1px solid grey",
                  margin: "5px",
                  fontSize: "14px",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
                onClick={handleCloseNavMenu}
                sx={{ color: "black", display: "block" }}
              >
                {page}
              </Typography>
            ))} */}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
